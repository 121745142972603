export const environment = {
  production: true,
  application_instance: '666c13dab9fe79f23dc13601',
  data: {
    authServiceUrl: 'http://api-adaptiv-ai-azure.justplug.fr/authentication/',
    api: 'http://api-adaptiv-ai-azure.justplug.fr/',
    ui: {
      UseEmailInvitations: true
    }
  }
};
